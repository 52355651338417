

img{
	padding-right: 0.5rem;
}

.table-content{
	padding-top:1rem;
}

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
    content: "\e36a";
    font-family: "e-icons";
}
