body{
    font-family: "Inter";
}

.fileName{
    font-weight: 600;
}
.description{
    font-weight: 600 !important;
    font-size: medium;
    margin-right: 0.5rem;
}
.description-child{
    font-size: medium;
    margin-right: 1.5rem;
}

.keytermedit{
    float: right;
    margin-top: 5px;
    margin-left: 5px;
}

.keytermcolumn{
    white-space: nowrap;
    vertical-align: top;
}

/* override .badge style for key terms */
#keytermfield{
    word-wrap: break-word;
    white-space: normal;
    text-align: left;
    max-width: 90px !important;
}

/* .e-tab-header{
    padding-bottom: 0.5rem !important;
    
} */
.e-item .e-toolbar-item{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.e-tab .e-tab-header .e-toolbar-item{
    background-color: #E4E4E4;
}
/* .e-tab-wrap{
    padding-bottom: 0.5rem !important;
} */

.e-tab .e-tab-header .e-toolbar-item .e-tab-text, .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover{
    color:#2B3443;
}

.e-tab .e-tab-header .e-toolbar-item.e-active{
    background-color: #2B3443 !important;
    
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text, .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover{
    color:#FFFFFF !important;
}

.e-tab .e-tab-header:not(.e-vertical) .e-indicator, .e-tab .e-tab-header.e-vertical .e-indicator {
    transition: none;
}

input {
    height: 40px !important;
}

.added-key{
    background-color: #f2f3ba;
    font-size: 12px;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;
    border: 2px solid rgb(239, 173, 18); 
}
.ai-key{
    font-size: 12px;
    background-color: #f9c007;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;
}

.del-ai-key{
    font-size: 12px;
    background-color: #f9c007;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;
    text-decoration: line-through;
}
