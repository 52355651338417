.skipLink a {
  position: absolute;
  left: -100px;
  top: -100px;
}

.navbar { margin-bottom: 0 !important; }

#logout:hover{
  background-color: #343a40;
}

hr{
  height: inherit !important;
  border: revert !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  background-color: white;
}

.skipLink a:focus {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  padding: 10px;
  color: #ffffff;
  background: #000000;
}

/* .vertical {
  border-left: 6px solid black;
  height: 1em;
  position:absolute;
  left: 50%;
} */
/* 
li {
  content: "|";
  float: left;
  border-right: 1px solid white;
  
	position: relative;
} */



/* li:last-child {
  border-right: none;
} */
ul li:hover{
  background-color: #505465 !important;
}


/* li:first-child {
  border-right: 1px solid;
  height: 50%;
  color:white;
} */

/* hr{
  border-left: 6px solid white;
  height: 1em;
}


.navLogo{

} */

.menu_item {
  font-size: 14px;
}

.submenu_container {
  width: 100%;
  position: absolute;
  background-color: rgb(218, 221, 223);
  color: #485564;
  display: flex;
  z-index: 1000;
}

.submenu_item {
  background-color: rgb(218, 221, 223);
  padding: 8px 12px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  font-size: 14px;
}

.submenu_item:hover {
  background-color: #485564;
  color: white;
}
