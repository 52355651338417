
#_dialog-header{
    border-bottom-width: 0px;
}

#_title{
    font-weight: 600;
    font-size:large;
    margin: 0.5rem 0.5rem;
}

.info{
    border: 1px solid #ADADAD;
    padding: 1rem 1rem 35% 1rem ;
    
}

.textboxes{
    margin: 0.5rem;
}

.constraint p{
    margin: 0 auto;
}
.constraint li{
    display: block !important;
}

/* .e-footer-content button{
    background-color: #EDEDF2 !important;
    border-color: #EDEDF2 !important;
    color:#505465 !important;
    padding: 0.5rem 1rem;
    font-size: medium;
    font-family: "Inter";
    font-weight: 550;

}

.e-footer-content button:nth-child(2){
    background-color: #2B3443 !important;
    border-color: #2B3443 !important;
    color:#FFFFFF !important;
} */