body{
    font-family: "Inter";
}

.modal-dialog{
    font-family: Roboto, "Segoe UI", GeezaPro, "DejaVu Serif", "sans-serif", -apple-system, BlinkMacSystemFont;
    max-width: none !important;
    justify-content: center;
}

.modal-content{
    width: 60% !important;
    padding: 20px;
}

.dialog-header{
    font-weight: 600 !important;
    font-size: large !important;
    padding-bottom: 20px;
}

.dialog-body{
    font-size: 14px !important;
}

.dialog-hr{
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    height: 1px;
}

.adjusted-inspection-result .e-label{
    font-size: 140px !important;
    margin-left: 20px;
}

.fileName{
    font-weight: 600;
}
.description{
    font-weight: 600 !important;
    font-size: medium;
    margin-right: 0.5rem;
}
.description-child{
    font-size: medium;
    margin-right: 1.5rem;
}

/* override .badge style for key terms */
#keytermfield{
    word-wrap: break-word;
    white-space: normal;
    text-align: left;
}

/* .e-tab-header{
    padding-bottom: 0.5rem !important;
    
} */
.e-item .e-toolbar-item{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.e-tab .e-tab-header .e-toolbar-item{
    background-color: #E4E4E4;
}
/* .e-tab-wrap{
    padding-bottom: 0.5rem !important;
} */

.e-tab .e-tab-header .e-toolbar-item .e-tab-text, .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover{
    color:#2B3443;
}

.e-tab .e-tab-header .e-toolbar-item.e-active{
    background-color: #2B3443 !important;
    
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text, .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover{
    color:#FFFFFF !important;
}

.e-tab .e-tab-header:not(.e-vertical) .e-indicator, .e-tab .e-tab-header.e-vertical .e-indicator {
    transition: none;
}

input {
    height: 40px !important;
}
