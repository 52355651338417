.back{
  background-color:#F7F7F7 !important;
  height: 100vh;
}
.login-container {
    flex: 1 0 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;

}
.login-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    
}
.container {
    width: 360px;
    padding: 15% 0 0;
    margin: auto;
   
  }
  .form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 400px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
  }
  .form input {
    font-family: Inter;
    outline: 0;
    width: 100%;
    border: 0;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
  }
  /* .form button {
    font-family: Inter;
    outline: 0;
    width: 30%;
    border: 0;
    padding: 15px;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    -moz-transition: all 0.3 ease;
    -ms-transition: all 0.3 ease;
    -o-transition: all 0.3 ease;
} */