.page-content{
    border: 1px solid #adadad;
    border-radius: 5px;
    background-color: #fff;
    padding: 1.5rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin: 0.5rem !important;
    box-sizing: border-box;

}

.highlighted-comment{
    color: #ff0000;
}

.e-grid {
    width: 100% !important;
}

.no-hover:hover {
    background-color: transparent !important;
    color: danger;
    border-color: danger;
}
