body{
    font-family: "Inter";
}

.fileName{
    font-weight: 600;
}
.description{
    font-weight: 600 !important;
    font-size: medium;
    margin-right: 0.5rem;
}
.description-child{
    font-size: medium;
    margin-right: 1.5rem;
}

/* override .badge style for key terms */
#keytermfield{
    word-wrap: break-word;
    white-space: normal;
    text-align: left;
    max-width: 150px !important;
}

#keytermfield-long{
    word-wrap: break-word;
    white-space: normal;
    text-align: left;
    max-width: 300px !important;
}

/* .e-tab-header{
    padding-bottom: 0.5rem !important;
    
} */
.e-item .e-toolbar-item{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.e-tab .e-tab-header .e-toolbar-item{
    background-color: #E4E4E4;
}
/* .e-tab-wrap{
    padding-bottom: 0.5rem !important;
} */

.e-tab .e-tab-header .e-toolbar-item .e-tab-text, .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover{
    color:#2B3443;
}

.e-tab .e-tab-header .e-toolbar-item.e-active{
    background-color: #2B3443 !important;
    
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text, .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover{
    color:#FFFFFF !important;
}

.e-tab .e-tab-header:not(.e-vertical) .e-indicator, .e-tab .e-tab-header.e-vertical .e-indicator {
    transition: none;
}

.form-count {
    padding-right: 30px;
}

.deleted-phrase {
    text-decoration: line-through;
}
