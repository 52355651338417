@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap4.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/bootstrap4.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-notifications/styles/material.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

html {
  position: relative;
  min-height: 100%;
}

body {
  /* margin-bottom: 160px !important;  */
  padding: 0;
  /* position: relative; */
  min-height: 100%;
  font-family: 'Inter', sans-serif !important;
}
 
.page-title{
  color: #505465;
  /* font-style:normal; 
   font-weight:bold;
   font-size:22px; 
   font-family:Inter ; */
   font: normal normal bold 22px/26px Inter;
}
.list-title{
  color: #505465;
  /* font-style:normal; 
  font-weight:bold;
  font-size:14px; 
  font-family:Inter ; */
  font: normal normal bold 14px/20px Inter;
}

.tab-title{
  color: #2B3443;
  /* font-style:normal; 
   font-weight:500;
   font-size:14px; 
   font-family:Inter ; */
   font: normal normal bold 14px/17px Inter;
}

.content-text{
  /* font-style:normal; 
   font-weight:500;
   font-size:14px; 
   font-family:Inter ; */
  color: #505465;
  font: normal normal 500 14px/20px Inter;
}
.content-text-inactive{
  /* font-style:normal; 
   font-weight:500;
   font-size:14px; 
   font-family:Inter ; */
  color: #A8AAB7;
  font: normal normal 500 14px/17px Inter;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 160px; 
}

/* Override Bootstrap Styling */

.btn-primary {
  background-color: #025fce !important;
}

.content{
  padding: 16px;
}

.cancel{
  background-color: #EDEDF2 !important;
}

.e-pager div.e-icons {
  color: #2B3443;
}
.e-pager .e-numericitem {
  background: #fff;
  border-right-color: #ddd;
  color: black;
}
.e-pager .e-currentitem {
  background: #2B3443;
  color: #fff ;
  opacity: 1;
}


.e-pager .e-currentitem:hover {
  background: #2B3443;
  color: #fff;
  opacity: 1;
}

.e-pager .e-icon-prev::before, .e-grid-menu .e-icon-prev::before {
  content: '\e904';
}

.e-dialog .e-footer-content {
  border-top: 0 ;
}

.btn{
  font: normal normal bold 16px/30px Inter !important;
}
.e-dropdownbase .e-list-item.e-item-focus, .e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover, .e-dropdownbase .e-list-item.e-hover {
  background-color: #2B3443;
  color: #FFFFFF;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9C9C9C !important;
  opacity: 1; /* Firefox */
  font-size: 14px !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #9C9C9C !important;
  font-size: 14px !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #9C9C9C !important;
  font-size: 14px !important;
}

input{
  height: 48px !important;
  padding-left: 1rem !important;
}

.e-calendar .e-header .e-date-icon-next::before {
  content: '\e848' !important;
}

.e-calendar .e-header .e-date-icon-prev::before {
  content: '\e84b' !important;
}
