body{
    font-family: "Inter";
}
.status-badge {
    padding: 6px;
    text-align: center;
    border-radius: 5px;
    font-weight: 550;
    font-family: "Inter"
  }
  

.status-badge span{
    opacity: 1 !important;
}


.e-grid .e-headercell {
   background-color: #f5f5f5;
}


.separator{
    border: 1px solid #D4D6E2;
    margin: auto 1rem;
}


.separator-left{
    margin-bottom: 3px;
}
.e-upload .e-file-select-wrap .e-btn, .e-upload .e-upload-actions .e-btn, .e-bigger.e-small .e-upload .e-file-select-wrap .e-btn, .e-bigger.e-small .e-upload .e-upload-actions .e-btn {
    background-color: #2B3443;
    font-family: "Inter";
    font-weight: 550;
    padding:0.5em 1rem;
}

.e-file-select, .e-upload-files, .e-upload.e-control-wrapper, .e-bigger.e-small .e-upload.e-control-wrapper{ 
    display: none; 
    width: 10%;
} 

 .e-btn, #target{
    margin-bottom: 6px !important;
}
.e-upload.e-control-wrapper, .e-bigger.e-small .e-upload.e-control-wrapper {
    
    width: 4% !important;
    display: inline-table;
    margin-right: none;
    border-style:none !important;
}
.e-upload .e-file-select-wrap{
    padding: 12px 0;
}


.status-count,.status-count *{
    display: inline-block;

}
.status-count *{
    padding-right: 0.5rem;
}

.ai-version{
    display: inline-block;
}

.badge{
    margin-left: 0.2rem;
}
.e-tooltip-wrap.e-popup {
    border: 1px solid #D4D6E2;
}
.e-tooltip-wrap .e-tip-content{
    background-color: #F5F5F5;
    background: #F5F5F5 0% 0% no-repeat padding-box;


    color: #505465;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-top {
    width: 0;
}

.e-tooltip {
    display:inline-block;
    /* margin-left: 1rem;   */
}


.fileNameWithTip:hover{
    text-decoration: underline;
}

.e-control .e-tooltip .e-lib{
    overflow: hidden;
    text-overflow: ellipsis;
}